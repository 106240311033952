import React from "reactn"
import { Box } from "@chakra-ui/core"

export const BoxWithTitle = ({
  title,
  children,
  boxprops,
  titleBg = "red.500",
  titleColor = "white",
  ...props
}) => (
  <Box m="3" borderWidth="1px" rounded="lg" {...boxprops}>
    <Box p="1" pl="4" color={titleColor} fontSize="xl" roundedTop="lg" bg={titleBg}>
      {title}
    </Box>
    <Box p="3" {...props}>
      {children}
    </Box>
  </Box>
)
