import React, { useState } from "reactn"
import { Flex } from "@chakra-ui/core"
import LoginForm from "../components/LoginForm"
import { auth, useAuth } from "../services/firebase"
import { Loading } from "../components/Loading"
import { Redirect } from "react-router-dom"

export default ({ type }) => {
  const [error, setError] = useState(null)
  const [initUser, user] = useAuth()
  if (initUser) return <Loading />
  return user ? (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  ) : (
    <Flex h="100%" justifyContent="center" alignItems="center">
      <LoginForm
        type={type}
        error={error}
        onSubmit={async (values, actions) => {
          // console.log("form submit", values, actions)
          setError(null)
          if (type === "login") {
            await auth
              // .signInWithEmailAndPassword("toto@frpz.fr", "azeazeaze")
              .signInWithEmailAndPassword(values.email, values.password)
              .then((user) => {
                // user.myvar = "test de var"
                // auth.updateCurrentUser(user)
                // auth.currentUser.updateProfile({ otherName: "TotO" })
                console.log("user is logged: ", user, auth.currentUser)
              })
              .catch((e) => {
                console.error(e)
                setError(e)
                actions.setSubmitting(false)
              })
            // const user = auth.currentUser
            // console.log("firebase user: ", user)
          } else {
            actions.setSubmitting(false)
          }
        }}
      />
    </Flex>
  )
}
