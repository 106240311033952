import React, { useState } from "reactn"
export function Json({ data, visible }) {
  const [isVisible, setvisible] = useState(visible)
  return null
  return (
    <div onClick={() => setvisible((o) => !o)}>
      {isVisible ? <pre>{JSON.stringify(data, null, 2)}</pre> : "Click to show JSON"}
    </div>
  )
}
