import { useState } from "react"

const format = {
  full: {
    hour12: false,
    dateStyle: "full",
    timeStyle: "short",
  },
  fulldate: {
    dateStyle: "full",
  },
  fulltime: {
    timeStyle: "medium",
  },
  medium: {
    hour12: false,
    dateStyle: "long",
    timeStyle: "short",
  },
  mediumdate: {
    dateStyle: "long",
  },
  mediumtime: {
    hour12: false,
    timeStyle: "short",
  },
  short: {
    hour12: false,
    dateStyle: "short",
    timeStyle: "short",
  },
  shortdate: {
    dateStyle: "short",
  },
  shorttime: {
    hour12: false,
    timeStyle: "short",
  },
}
export const fullDate = (seconds, type = "fulldate") =>
  seconds === undefined
    ? undefined
    : typeof seconds === "number"
    ? new Date(seconds * 1000).toLocaleString(undefined, format[type])
    : seconds.toLocaleString(undefined, format[type])

// Hook

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.

  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
