import { Grid } from "@chakra-ui/core"
import React from "reactn"
import { useParams } from "react-router-dom"
import { Loading } from "../../components/Loading"
import { NotFound } from "../../components/NotFound"
import { useCollection, useDoc } from "../../services/firebase"
import { ShowCast } from "./ShowCast"
import { ShowDetails } from "./ShowDetails"
import { ShowEpisodes } from "./ShowEpisodes"

const ShowPage = (...args) => {
  let { id } = useParams()
  let [show, showError, showRefresh] = useDoc("shows", id)
  let [cast, castError, castRefresh] = useCollection(
    "cast",
    { where: [[`caracters.${id}.show.id`, "==", Number(id)]] },
    [id]
  )
  let [episodes, episodesError, episodesRefresh] = useCollection(
    "episodes",
    {
      where: [["show.id", "==", Number(id)]],
      orderBy: [["season"], ["number", "asc"]],
    },
    [id]
  )
  if (showError) return `show error: ${showError}`
  if (castError) return `cast error: ${castError}`
  if (episodesError) return `episodes error: ${episodesError}`
  if (!show) return <Loading />
  if (!cast) return <Loading />
  if (!episodes) return <Loading />
  if (show.error === "Not found") return <NotFound />
  const refreshAll = () => {
    showRefresh()
    castRefresh()
    episodesRefresh()
  }
  return (
    <Grid
      templateColumns={{ md: "60% 40%", base: "100%" }}
      templateAreas={{
        md: '"ShowDetails ShowDetails" "ShowCast ShowEpisodes"',
        base: '"ShowDetails" "ShowCast" "ShowEpisodes"',
      }}
    >
      <ShowDetails show={show} refresh={refreshAll} />
      <ShowCast cast={cast} showId={id} />
      <ShowEpisodes episodes={episodes} />
    </Grid>
  )
}
export default ShowPage
