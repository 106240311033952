import { Box, Button, FormErrorMessage } from "@chakra-ui/core"
import { Formik, Form } from "formik"
import React from "reactn"
import { Link } from "react-router-dom"
import { BoxWithTitle } from "./BoxWithTitle"
import CustomField from "./CustomField"
import * as Yup from "yup"

const schemaLogin = Yup.object().shape({
  email: Yup.string()
    .email("L'adresse email n'est pas valide")
    .required("L'adresse email est obligatoire"),
  password: Yup.string()
    .min(6, "Le mot de passe doit faire au moins 6 caractères")
    .required("Le mot de passe est obligatoire"),
})
const schemaRegister = schemaLogin.concat(
  Yup.object().shape({
    fullname: Yup.string()
      .min(2, "Le nom complet doit faire au moins 2 caractères")
      .required("Le nom complet est obligatoire"),
    password2: Yup.string()
      .min(6, "Le mot de passe doit faire au moins 6 caractères")
      .oneOf(
        [Yup.ref("password"), null],
        "La confirmation du mot de passe doit être identique au précédent"
      )
      .required("La confirmation du mot de passe est obligatoire"),
  })
)

export default ({ type = "register", onSubmit, error }) => (
  <BoxWithTitle
    title={type === "login" ? "Se connecter" : "Création de compte"}
    w={{ base: "95vw", sm: "400px" }}
    p="2"
  >
    <Formik
      initialValues={{ fullname: "", email: "toto@frpz.fr", password: "", password2: "" }}
      validationSchema={type === "login" ? schemaLogin : schemaRegister}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          {type === "register" && <CustomField name="fullname" label="Nom Complet" />}
          <CustomField name="email" label="Email" />
          <CustomField name="password" type="password" label="Mot de passe" />
          {type === "register" && (
            <CustomField name="password2" type="password" label="Vérification du mot de passe" />
          )}
          <Button
            mt={4}
            w="100%"
            variantColor="red"
            isLoading={props.isSubmitting}
            loadingText={`${type === "login" ? "Connection" : "Création"} en cours...`}
            type="submit"
          >
            {type === "login" ? "Se connecter" : "Créer le compte"}
          </Button>
          {error && (
            <Box m="3" p="2" color="pink.200" borderWidth="1px" borderColor="pink.400" rounded="lg">
              {error.message}
            </Box>
          )}
        </Form>
      )}
    </Formik>

    <Box textAlign="right" fontStyle="italic" mt="9px">
      {type === "register" ? (
        <Link to="/login">J'ai déja un compte</Link>
      ) : (
        <Link to="/register">Je n'ai pas de compte</Link>
      )}
    </Box>
  </BoxWithTitle>
)
