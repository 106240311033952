import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/core"
import { Field } from "formik"
import React from "reactn"

function PasswordInput(props) {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <Input pr="4.5rem" type={show ? "text" : "password"} {...props} />
      <InputRightElement width="4.5rem">
        {/* <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button> */}
        <IconButton
          color="black"
          icon={show ? "view-off" : "view"}
          h="1.75rem"
          size="sm"
          onClick={handleClick}
          variant="ghost"
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default ({ name, label, type = "text", useLabel = true }) => (
  <Box my="4">
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl isInvalid={meta.touched && meta.error}>
          {useLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
          {type === "password" ? (
            <PasswordInput {...field} id={name} placeholder={label} color="black" />
          ) : (
            <Input {...field} id={name} type={type} placeholder={label} color="black" />
          )}
          <FormErrorMessage color="pink.200" fontWeight="bold">
            {meta.error}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  </Box>
)
