import React from "react"
import { Box, Heading } from "@chakra-ui/core"

const Accueil = () => (
  <Box m="10" p="10">
    <Heading>Mes séries</Heading>
    <Box>
      Ce site permet de garder la liste de ses séries et suivre l'arrivée de nouveaux épisodes. Vous
      devez créer un compte pour pouvoir l'utiliser
    </Box>
  </Box>
)

export default Accueil
