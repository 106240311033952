import { Badge, Box } from "@chakra-ui/core"
import React from "reactn"
import { ListBadge } from "../../components/ListBadge"
import { Externals } from "./Externals"
import { fullDate } from "../../components/Utils"

const Line_ = ({ title, data, children }) =>
  data || children ? (
    <tr>
      <th>{title}</th>
      <td>
        {data}
        {children}
      </td>
    </tr>
  ) : null

const Line = React.memo(Line_)

const ShowInfos_ = ({ show, ...props }) => (
  <Box borderWidth="1px" rounded="lg" w={{ md: "50%", base: "100%" }} p="3" {...props}>
    <table style={{ width: "100%" }}>
      <tbody>
        <Line title="Status:">
          <Badge rounded="full" px="5" bg={show.status === "Running" ? "green.300" : "red.300"}>
            {show.status}
          </Badge>
        </Line>
        <Line
          title="Network:"
          data={show?.network ? `${show?.network?.name} (${show?.network?.country?.name})` : null}
        />
        <Line title="WebChannel:" data={show?.webChannel ? `${show?.webChannel?.name}` : null} />
        <Line title="Langue" data={show?.language} />
        <Line title="Première:" data={show?.premiered?.split(/-/).reverse().join("/")} />
        <Line title="Durée:" data={show?.runtime ? `${show?.runtime} min` : null} />
        <Line
          title="Diffusion:"
          data={
            show?.schedule?.time !== ""
              ? `${show?.schedule?.days?.join("")} at ${show?.schedule?.time}`
              : null
          }
        />
        <Line
          title="Genres:"
          data={
            show.genres && show.genres.length > 0
              ? show.genres.map((g) => <ListBadge key={g} g={g} />)
              : null
          }
        />
        <Line title="Ratings:" data={show?.rating?.average} />
        <Line title="Type:" data={show.type} />
        <Line title="Updated:" data={fullDate(show?.updated)} />
        <Line title="Synced:" data={fullDate(show?.last_update?.toDate())} />
        <tr>
          <td colSpan="2">
            <Externals self={show?.url} others={show?.externals} />
          </td>
        </tr>
      </tbody>
    </table>
  </Box>
)
export const ShowInfos = React.memo(ShowInfos_)
