import React from "reactn"
export const ErrorMsg = ({ msg }) => (
  <p
    style={{
      color: "red",
      margin: 20,
      padding: 20,
      border: "3px dashed red",
      background: "antiquewhite",
      wordBreak: "break-all",
    }}
  >
    {msg.toString()}
  </p>
)
