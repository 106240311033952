import React from "reactn"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyC2LXYgvpfjFpI-b23tx9TEGHd-lNtdTek",
  authDomain: "series-firebase.firebaseapp.com",
  databaseURL: "https://series-firebase.firebaseio.com",
  projectId: "series-firebase",
  storageBucket: "series-firebase.appspot.com",
  messagingSenderId: "921086053248",
  appId: "1:921086053248:web:fe68b1d7784da7ab944f65",
  measurementId: "G-DM134KNNEL",
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
// auth.createUserWithEmailAndPassword("toto@frpz.fr", "azeazeaze")

export const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = auth.currentUser
    return [!user, user, {}]
  })
  function onChange(user) {
    console.log("user changed", user)
    if (user)
      user
        .getIdTokenResult()
        .then((tok) => {
          // console.log(tok)
          setState([false, user, tok.claims])
        })
        .catch(console.error)
    else setState([false, null, {}])
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}

// auth
//   .signInWithEmailAndPassword("toto@frpz.fr", "azeazeaze")
//   .then(user => {
//     // user.myvar = "test de var"
//     // auth.updateCurrentUser(user)
//     // auth.currentUser.updateProfile({ otherName: "TotO" })
//     console.log("user: ", user, auth.currentUser)
//   })
//   .catch(console.error)
// const user = auth.currentUser
// console.log("firebase user: ", user)

export const db = firebase.firestore()

// console.log("hostname", window.location.hostname)
const useLocalDB = false
if (useLocalDB && window.location.hostname === "localhost") {
  db.settings({
    host: "localhost:8080",
    ssl: false,
  })
  firebase.functions().useFunctionsEmulator("http://localhost:5001")
}

export const useDoc = (name, id) => {
  const [doc, setDoc] = React.useState(null)
  const [error, setError] = React.useState(null)

  const refresh = () => {
    setDoc(null)
    db.collection(name)
      .doc(id)
      .get()
      .then((snap) => (snap.exists ? snap.data() : { error: "Not found" }))
      .then(setDoc)
      .catch(setError)
  }
  React.useEffect(refresh, [id])
  return [doc, error, refresh]
}

export const useCollection = (
  name,
  { where = [], orderBy = [], sortAfter = null },
  updateRef = []
) => {
  const [shows, setShows] = React.useState(null)
  const [error, setError] = React.useState(null)

  const refresh = () => {
    setShows(null)
    let col = db.collection(name) //
    // col = col.where("id", "==", 82)
    where.forEach(([a, b, c]) => (col = col.where(a, b, c)))
    orderBy.forEach((o) => (col = col.orderBy(...o)))
    // for (let [a, b, c] of where) col.where(a, b, c)
    // if (orderBy) col = col.orderBy(orderBy)
    col
      .limit(1000)
      .get()
      .then((snap) => snap.docs.map((d) => d.data()))
      .then((res) => setShows(typeof sortAfter === "function" ? res.sort(sortAfter) : res))
      .catch(setError)
  }

  React.useEffect(refresh, updateRef)
  return [shows, error, refresh]
}

export default firebase
