import React from "reactn"
import { BoxWithTitle } from "../../components/BoxWithTitle"
import { Json } from "../../components/Json"
import Person from "../../components/Person"

const byOrder = (showId) => (a, b) =>
  a?.caracters[showId]?.order < b?.caracters[showId]?.order ? -1 : 1

const ShowCast_ = ({ cast = [], showId }) => (
  <BoxWithTitle
    display="grid"
    title="Casting"
    gridTemplateColumns={[
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
      "repeat(3, 1fr)",
      "repeat(4, 1fr)",
      "repeat(5, 1fr)",
    ]}
    gridGap={3}
    m="3"
    p="2"
    borderWidth="1px"
    rounded="lg"
    gridArea="ShowCast"
    alignContent="start"
    justifyItems="center"
  >
    {cast.sort(byOrder(showId)).map((c) => (
      <Person key={c.id} person={c} showId={showId} />
    ))}
    <Json data={cast} />
  </BoxWithTitle>
)
export const ShowCast = React.memo(ShowCast_)
