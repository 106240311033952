import React, { useGlobal, useRef, useState } from "reactn"
import useForceUpdate from "use-force-update"
import {
  Box,
  Flex,
  Image,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  DarkMode,
  Button,
  Heading,
  IconButton,
  Input,
  Checkbox,
  Stack,
  PseudoBox,
} from "@chakra-ui/core"
// import React, { useState } from "reactn"
import { Link } from "react-router-dom"
import { Loading } from "../components/Loading"
import { useCollection } from "../services/firebase"
// import groupBy from "lodash/groupBy"
// import filter from "lodash/filter"
import _, { groupBy, filter } from "lodash"
import { ShowInfos } from "./Show/ShowInfos"
import { ErrorMsg } from "../components/ErrorMsg"

// function getData() {
//   return db
//     .collection("shows")
//     .get()
//     .then(snap => snap.docs.map(d => d.data()))
//   // .catch(e => console.log("[getdata] error", e))
// }

// const ShowListOld = ({ shows }) => (
//   <table>
//     <thead>
//       <tr>
//         <th></th>
//         <th>id</th>
//         <th>Nom</th>
//         <th>status</th>
//       </tr>
//     </thead>
//     <tbody>
//       {shows.map(s => (
//         <tr key={s.name}>
//           <td>
//             <Link to={`/show/${s.id}`}>
//               {s.image && (
//                 <Image rounded="lg" shadow="xl" alt="" h="200px" objectFit="cover" src={s.image.medium.replace("http://", "https://")} />
//               )}
//             </Link>
//           </td>
//           <td>{s.id}</td>
//           <td>{s.name}</td>
//           <td>{s.status}</td>
//         </tr>
//       ))}
//     </tbody>
//   </table>
// )

const ShowList = ({ shows }) => {
  const [global] = useGlobal()
  // console.log("Global state", global)
  return (
    <Flex wrap="wrap" justifyContent="space-around" m="10">
      {applyFilters(shows, global).map((s) => (
        <Box
          key={s?.id}
          w={{ base: "120px", sm: "160px" }}
          position="relative"
          borderWidth="0px"
          m="1"
          minH="100px"
        >
          <Link to={`/show/${s.id}`}>
            <Image
              src={s?.image?.medium?.replace("http://", "https://")}
              fallbackSrc={`https://via.placeholder.com/160x225?text=Chargement...`}
            />
          </Link>
          <Box
            position="absolute"
            top="0"
            right="0"
            left="0"
            // opacity="0.5"
            // bg="gray.50"
            color="black"
            fontWeight="bolder"
            textShadow="0px 0px 7px #cfc2c2"
            background="linear-gradient(#ffffffdb, #4747470d)"
            p="1"
          >
            <Text fontSize="md">{s?.name}</Text>
            {/* <Text fontSize="xs">as {current?.name}</Text> */}
          </Box>
          <Box position="absolute" top="-5px" right="-5px">
            <Popover trigger="hover">
              <PopoverTrigger>
                <IconButton
                  icon="info"
                  size="sm"
                  isRound
                  variant="solid"
                  variantColor="red"
                  bg="gray.300"
                  h="1.4rem"
                  minW="1.4rem"
                />
              </PopoverTrigger>
              <DarkMode>
                <PopoverContent zIndex={4} minW={{ base: "90vw", md: "40vw" }} borderWidth="0">
                  <PopoverHeader>
                    <Link to={`/show/${s.id}`}>
                      <Heading as="h4">{s?.name}</Heading>
                    </Link>
                  </PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <ShowInfos show={s} w="100%" />
                  </PopoverBody>
                </PopoverContent>
              </DarkMode>
            </Popover>
          </Box>
        </Box>
      ))}
    </Flex>
  )
}

const byObject = (a, b) => (a[0] > b[0] ? -1 : 1)
const bySize = (a, b) => (a[1].length > b[1].length ? -1 : 1)

const GroupOptions = ({ shows = [], by, sort = "object" }) => {
  const list = groupBy(shows, by)
  const sortedList = Object.entries(list).sort(sort === "object" ? byObject : bySize)
  // console.log("sorted list", sortedList)
  const [sel, setSel] = useGlobal(by)
  const forceUpdate = useForceUpdate()
  // console.log("GroupOption: ", by, sel)
  if (sel === undefined) {
    setSel(Object.keys(list))
    forceUpdate()
    return null
  }
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <PseudoBox
          textTransform="capitalize"
          borderWidth="1px"
          borderColor="white"
          rounded="lg"
          cursor="pointer"
          p={3}
          m={1}
          _hover={{ bg: "red.300" }}
        >
          {by} ({`${sel.length}/${Object.keys(list).length}`})
        </PseudoBox>
      </PopoverTrigger>
      <DarkMode>
        <PopoverContent zIndex={4}>
          <Stack spacing={1} p={2}>
            <Box
              textTransform="capitalize"
              cursor="pointer"
              onClick={() => setSel(sel.length === 0 ? Object.keys(list) : [])}
            >
              {by} ({`${sel.length}/${Object.keys(list).length}`})
            </Box>
            {sortedList.map(([type, data]) => (
              <Box key={type}>
                <Checkbox
                  isChecked={(sel || []).includes(type)}
                  onChange={(e) => {
                    e.persist()
                    console.log("checked", type, e.target.checked)
                    setSel(e.target.checked ? [type, ...sel] : [...sel.filter((t) => t !== type)])
                  }}
                >
                  {type} ({data?.length})
                </Checkbox>
              </Box>
            ))}
          </Stack>
        </PopoverContent>
      </DarkMode>
    </Popover>
  )
}

const TextFilter = () => {
  const forceUpdate = useForceUpdate()
  const [filter, setFilter] = useGlobal("showFilter")
  const [filterDebounce, setFilterDebounce] = useGlobal("showFilterDebounce")
  // console.log("text filter: ", filter)
  if (filter === undefined) setFilter("") && forceUpdate()
  if (filterDebounce === undefined) setFilterDebounce("") && forceUpdate()
  const dSetFilter = useRef(
    _.debounce((val) => /*console.log("dSetFilter", val) ||*/ setFilterDebounce(val), 500)
  ).current
  return (
    <Flex w={{ base: "95vw", md: "70vw" }} p={5} m="auto">
      <Input
        placeholder="Filtrer les séries"
        color="black"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value)
          dSetFilter(e.target.value)
        }}
      />
    </Flex>
  )
}
const applyFilters = (shows, state) => {
  // console.log("apply filter", shows, state)
  if (state.showFilterDebounce !== "")
    return shows.filter((s) => RegExp(state.showFilterDebounce, "ig").test(s.name))
  else
    return shows
      .filter((s) => state["status"].includes(_.get(s, "status") || "undefined"))
      .filter((s) => state["network.name"].includes(_.get(s, "network.name") || "undefined"))
      .filter((s) => state["webChannel.name"].includes(_.get(s, "webChannel.name") || "undefined"))
      .filter((s) => state["language"].includes(_.get(s, "language")))
      .filter((s) =>
        state["rating.average"].includes((_.get(s, "rating.average") || "null").toString())
      )
}
//filter(shows, (o) => [9.3, 9.1].includes(_.get(o, "rating.average")))

function ListShowsPage() {
  const uid = "uSZUuIYtbObKTIkVuEvaUKoreTI2"
  const [shows, errorShows, refresh] = useCollection("shows", {
    // where: [["id", "in", [82, 83]]],
    // where: [["status", "==", "Running"]],
    // where: [[`favorites.${uid}`, "==", true]],
    // orderBy: [["name"]],
    sortAfter: (a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1),
  })

  if (errorShows) return <ErrorMsg msg={errorShows} />
  if (!shows) return <Loading />
  // console.log( "Shows: ", shows.map((s) => s?.name))
  return (
    <Box>
      <TextFilter />
      <Flex
        m="3"
        justifyContent="space-evenly"
        flexWrap={{ base: "wrap", md: "wrap" }}
        alignItems="center"
      >
        <GroupOptions shows={shows} by="status" sort="bySize" />
        <GroupOptions shows={shows} by="network.name" sort="bySize" />
        <GroupOptions shows={shows} by="webChannel.name" sort="bySize" />
        <GroupOptions shows={shows} by="rating.average" />
        <GroupOptions shows={shows} by="language" />
        <Button leftIcon="repeat" onClick={refresh} color="gray.900">
          Mettre à jour
        </Button>
      </Flex>
      <ShowList shows={shows} ok={global.showFilter} />
    </Box>
  )
}

export default ListShowsPage
