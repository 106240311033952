import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  useDisclosure,
  PseudoBox,
} from "@chakra-ui/core"
import React from "reactn"
import { NavLink, Link, useHistory } from "react-router-dom"
import { useAuth } from "../services/firebase"
import LogoutButton from "./LogoutButton"
import firebase from "../services/firebase"
const grantAdmin = firebase.functions().httpsCallable("grantAdmin")

const MenuItems = ({ children, to }) => (
  <PseudoBox as={NavLink} to={to} px={10} py={3} display="block" _hover={{ bg: "red.300" }}>
    {children}
  </PseudoBox>
)

const Header = ({ children, userData: { initUser, user, userInfos }, ...props }) => {
  const [show, setShow] = React.useState(false)
  // const [initUser, user, userInfos] = useAuth()
  const handleToggle = () => setShow(!show)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  return (
    <Flex direction="column">
      <Flex
        as="nav"
        h="70px"
        align="center"
        justify="space-between"
        wrap="wrap"
        px="1.5rem"
        bg="red.500"
        color="white"
        boxShadow="7px 6px 19px 7px #545252"
        zIndex="2"
        {...props}
      >
        <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
          <svg fill="white" width="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box>

        <Flex align="center" mr={5}>
          <Heading as={NavLink} to="/" size="lg">
            Mes Séries
          </Heading>
        </Flex>

        {user && (
          <Box
            display={{ base: show ? "block" : "none", md: "flex" }}
            position={{ base: "absolute", md: "relative" }}
            width={{ base: "full", md: "auto" }}
            top={{ base: "70px", md: 0 }}
            borderTopWidth={{ base: "2px", md: 0 }}
            left={0}
            bg="red.500"
            zIndex="3"
            alignItems="center"
            flexGrow={1}
            onClick={() => setShow(false)}
          >
            <MenuItems to="/all">Liste</MenuItems>
            <MenuItems to="/current">Cette semaine</MenuItems>
            <MenuItems to="/next">À venir</MenuItems>
            <MenuItems to="/addShow">Ajouter</MenuItems>
          </Box>
        )}

        <Box display={{ base: show ? "block" : "block", md: "block" }} mt={{ base: 0, md: 0 }}>
          {initUser ? (
            "..."
          ) : !user ? (
            <Button as={Link} to="/login" bg="transparent" border="1px">
              Se connecter
            </Button>
          ) : (
            <Avatar
              cursor="pointer"
              onClick={onOpen}
              name={user.displayName}
              src={user.photoUrl || "https://www.devopso.fr/images/FrancisPugnere.png"}
            />
          )}
        </Box>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Mon Profil</DrawerHeader>

            <DrawerBody>
              {initUser || !user ? (
                "..."
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Avatar name={user.displayName} src={user.photoUrl} size="2xl" />
                  <Heading>{user.displayName}</Heading>
                  <div>{user.email}</div>
                  {/* <div style={{ wordBreak: "break-all" }}>{JSON.stringify(userInfos)}</div> */}
                </Box>
              )}
              {userInfos.admin === true && (
                <Box>
                  <Heading>Admin</Heading>
                  <MenuItems to="/updateall">Mettre à jour toutes les séries</MenuItems>
                  <Button
                    onClick={(e) =>
                      grantAdmin(userInfos.email).then(console.log).catch(console.error)
                    }
                  >
                    Make Admin
                  </Button>
                </Box>
              )}
            </DrawerBody>

            <DrawerFooter>
              {/* <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button color="blue">Save</Button> */}
              <LogoutButton
                onSuccess={() => {
                  onClose()
                  history.push("/")
                }}
              />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
      <Box overflow="auto" h="calc(100vh - 90px)" bg="#585858" color="white">
        {children}
      </Box>
    </Flex>
  )
}

export default Header
