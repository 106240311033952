import React from "reactn"
import ReactDOM from "react-dom"
import { ThemeProvider, ColorModeProvider, theme } from "@chakra-ui/core"
import { CSSReset } from "@chakra-ui/core"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import addReactNDevTools from "reactn-devtools"
addReactNDevTools()

console.info("theme", theme)
// example theme.js
const customtheme = {
  ...theme,
  // breakpoints: ["30em", "48em", "62em", "80em"],
  fonts: {
    heading: '"Avenir Next", sans-serif',
    body: "system-ui, sans-serif",
    mono: "Menlo, monospace",
  },

  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
}

// Use at the root of your app
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customtheme}>
      <ColorModeProvider>
        <CSSReset />
        <App />
      </ColorModeProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
