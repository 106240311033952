import React, { setGlobal } from "reactn"
// import "./App.css"
// import logo from "./logo.svg"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Header from "./components/Header"
import ListShowsPage from "./pages/ListShows"
import ShowPage from "./pages/Show"
import CurrentShows from "./pages/CurrentShows.jsx"
import NextShows from "./pages/NextShows.jsx"
import Login from "./pages/Login"
import AddShow from "./pages/AddShow"
import UpdateAll from "./pages/UpdateAll"
import Accueil from "./pages/Accueil"
import { useAuth } from "./services/firebase"
import { Loading } from "./components/Loading"

setGlobal({ test: "ok" })

function PrivateRoute({ children, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const [initUser, user, userInfos] = useAuth()
  // console.log("APP", initUser, user)
  return (
    <Router>
      <Header userData={{ initUser, user, userInfos }}>
        {initUser ? (
          <Loading />
        ) : (
          <Switch>
            <Route exec path="/login">
              <Login type="login" />
            </Route>
            <Route exec path="/register">
              <Login type="register" />
            </Route>
            <PrivateRoute user={user} path="/show/:id">
              <ShowPage />
            </PrivateRoute>
            <PrivateRoute user={user} exec path="/current">
              <CurrentShows />
            </PrivateRoute>
            <PrivateRoute user={user} exec path="/next">
              <NextShows />
            </PrivateRoute>
            <PrivateRoute user={user} exec path="/addshow">
              <AddShow />
            </PrivateRoute>
            <PrivateRoute user={user} exec path="/all">
              <ListShowsPage />
            </PrivateRoute>
            <PrivateRoute user={user} exec path="/updateall">
              <UpdateAll />
            </PrivateRoute>
            <Route exact path="/">
              <Accueil />
            </Route>
          </Switch>
        )}
      </Header>
    </Router>
  )
}

export default App
