import { Box, Button } from "@chakra-ui/core"
import React from "reactn"

export const Externals = ({ self = null, others = {} }) => {
  const base = { imdb: (n) => `https://www.imdb.com/title/${n}/` }
  let ext = {}
  if (self) ext["TV Maze"] = self.replace("http://", "https://")
  for (let net in others) {
    if (base[net]) ext[net.toUpperCase()] = base[net](others[net])
  }
  return (
    <Box textAlign="center">
      {Object.entries(ext).map(([name, link]) => (
        <Button
          key={name}
          rightIcon="external-link"
          as="a"
          href={link}
          mx="2"
          my="4"
          variant="solid"
          variantColor="teal"
          target="_blank"
        >
          {name}
        </Button>
      ))}
    </Box>
  )
}
