import React from "reactn"
import { Button } from "@chakra-ui/core"
import { auth } from "../services/firebase"

export default ({ onSuccess = () => {} }) => (
  <Button
    variantColor="red"
    width="full"
    onClick={() =>
      console.log("se deconnecter") ||
      auth
        .signOut()
        .then(() => console.log("l'utilisateur est déconnecté") || onSuccess())
        .catch((e) => console.error("Erreur pendant la déconnection", e))
    }
  >
    Se déconnecter
  </Button>
)
