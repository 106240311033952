import React, { useState } from "react"
import { Box, Heading, Flex, Checkbox, Button } from "@chakra-ui/core"
import { ErrorMsg } from "../components/ErrorMsg"
import { Loading } from "../components/Loading"
import { useCollection } from "../services/firebase"
import moment from "moment"
import firebase from "../services/firebase"
import { Json } from "../components/Json"
const updateShow = firebase.functions().httpsCallable("callUpdateShow")

function UpdateAll() {
  const [shows, errorShows, refresh] = useCollection("shows", {
    // where: [["id", "in", [82, 83]]],
    where: [["status", "in", ["To Be Determined", "Running", "In Development"]]],
    // where: [[`favorites.${uid}`, "==", true]],
    // orderBy: [["name"]],
    sortAfter: (a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1),
  })
  //   const [sel, setSel] = useState(shows.map((s) => s.id))
  const [notsel, setNotsel] = useState([])
  const [updating, setUpdating] = useState(false)
  const [res, setRes] = useState([])

  if (errorShows) return <ErrorMsg msg={errorShows} />
  if (!shows) return <Loading />
  //   console.log("shows: ", shows)
  return (
    <Box pb="20">
      <Heading m="4">Mettre à jour toutes les séries en cours</Heading>
      {/* <div>{JSON.stringify(shows)}</div> */}
      <Flex wrap="wrap" alignContent="center">
        {shows?.map((s) => (
          <Box key={s.id} m="3" p="2" rounded="lg" border="1px" flexGrow={1}>
            <Checkbox
              isChecked={!(notsel || []).includes(s.id)}
              onChange={(e) => {
                e.persist()
                // console.log("checked", s.name, e.target.checked)
                setNotsel((notsel) =>
                  !e.target.checked ? [s.id, ...notsel] : [...notsel.filter((t) => t !== s.id)]
                )
              }}
            >
              {s.name}
            </Checkbox>
            <br />
            Updated: {moment(s.updated * 1000).fromNow()}
            <br />
            Synced: {moment(s.last_update.toDate()).fromNow()}
          </Box>
        ))}
        <Box flexGrow={10} />
      </Flex>

      <Flex p="4" justifyContent="center">
        <Button
          variantColor="green"
          isLoading={updating}
          loadingText="Mise à jour en cours..."
          onClick={() => {
            setUpdating(true)
            Promise.all(
              shows
                .filter((s) => !notsel.includes(s.id))
                .map((s) =>
                  updateShow(s.id).then((res) => {
                    // console.log("update show then: ", res)
                    setRes((o) => [...o, res?.data])
                    return res
                  })
                )
            )
              .then((res) => {
                // console.log("promise all then: ", res)
                setRes((o) => [...o, [`Mise à jour de ${res.length} séries terminée`]])
                setUpdating(false)
              })
              .catch(console.error)
          }}
        >
          Mettre à jour les séries sélectionnées
        </Button>
        <Button
          variantColor="blue"
          ml="1"
          onClick={() => setNotsel((o) => (o.length > 0 ? [] : shows.map((ms) => ms.id)))}
        >
          (de-)sélectionner tout
        </Button>
      </Flex>

      {res.length > 0 && (
        <Box p="8">
          <Heading as="h2">Résultats:</Heading>
          {res.map((r, i) => (
            <Box key={i} border="1px solid white" rounded="lg" p="5" m="2">
              {Array.isArray(r) && r.map((l, y) => <Box key={y}>{l}</Box>)}
            </Box>
          ))}
          <Flex p="4" justifyContent="center">
            <Button
              variantColor="green"
              onClick={() => {
                setRes([])
                refresh()
              }}
            >
              Actualiser
            </Button>
          </Flex>
          <Json data={res} visible={true} />
        </Box>
      )}
    </Box>
  )
}
export default UpdateAll
