import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  DarkMode,
  Flex,
  Heading,
  Image,
  Link,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
} from "@chakra-ui/core"
import groupBy from "lodash/groupBy"
import padStart from "lodash/padStart"
import React from "reactn"
import { HtmlBox } from "../../components/HtmlBox"
import { Json } from "../../components/Json"
import { BoxWithTitle } from "../../components/BoxWithTitle"

const ShowEpisodes_ = ({ episodes }) => {
  let groupSeasons = groupBy(episodes, "season")
  return (
    <BoxWithTitle
      title="Episodes"
      m="3"
      p="2"
      borderWidth="1px"
      rounded="lg"
      gridArea="ShowEpisodes"
    >
      <Accordion defaultIndex={Object.keys(groupSeasons).length - 1}>
        {Object.entries(groupSeasons).map(([season, eps]) => (
          <AccordionItem key={season} borderWidth="0">
            <AccordionHeader
              _expanded={{ bg: "tomato", color: "white" }}
              mb="2"
              borderWidth="2px"
              borderColor="tomato"
              rounded="lg"
            >
              Saison <b> {padStart(season, 2, "0")}</b>
            </AccordionHeader>

            <AccordionPanel>
              {eps.map((e) => (
                <Popover key={e.id} usePortal closeOnBlur={true}>
                  <PopoverTrigger>
                    <PseudoBox display="flex" cursor="pointer" _hover={{ bg: "gray.500" }}>
                      <Box pr="2" color="gray.500">
                        {padStart(e.number, 2, "0")}
                      </Box>
                      <Box flexGrow="1" fontWeight="semibold">
                        {e.name}
                      </Box>
                      <Box>{e?.airdate?.split(/-/).reverse().join("/")}</Box>
                    </PseudoBox>
                  </PopoverTrigger>
                  <DarkMode>
                    <PopoverContent
                      border="0"
                      zIndex={4}
                      color="white"
                      minWidth={{ base: "90vw", md: "60vw" }}
                      p="2"
                    >
                      <PopoverCloseButton />
                      <Heading as="h3">{`S${padStart(season, 2, "0")}E${padStart(
                        e.number,
                        2,
                        "0"
                      )} - ${e.name}`}</Heading>
                      <Flex p={2}>
                        {e?.image && (
                          <Image
                            w="40%"
                            objectFit="cover"
                            src={e?.image?.medium?.replace("http://", "https://")}
                            alt=""
                            alignSelf="baseline"
                            rounded="lg"
                          />
                        )}
                        <Box flexGrow="1" ml="2">
                          <HtmlBox my="3" p="2" borderLeftWidth="5px" borderLeftColor="red.200">
                            {e.summary ? e.summary : "<i>Pas encore de résumé disponible</i>"}
                          </HtmlBox>
                          <Link target="_blank" href={e.url}>
                            Plus d'infos
                          </Link>
                        </Box>
                      </Flex>
                    </PopoverContent>
                  </DarkMode>
                </Popover>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Json data={episodes} />
    </BoxWithTitle>
  )
}
export const ShowEpisodes = React.memo(ShowEpisodes_)
