import React, { useState } from "reactn"

import firebase from "../services/firebase"
import { Button, Box, Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/core"
import { Link } from "react-router-dom"
// const testoncall = firebase.functions().httpsCallable("testoncall")
const updateShow = firebase.functions().httpsCallable("callUpdateShow")

const testdata = {
  data: [
    "Le show id 169 a été trouvé: 'Breaking Bad'",
    "1 element écrit dans la collection 'shows' : id 169",
    "11 élements écrits dans la collection 'cast'",
    "62 élements écrits dans la collection 'episodes'",
  ],
}
const ButtonUpdateShow = ({ add, id, after }) => {
  const [res, setRes] = useState(null)
  const [error, setError] = useState(null)
  const [updating, setUpdating] = useState(null)
  if (error) return <Box>{error.message}</Box>
  if (res) {
    const [first, ...all] = res?.data || []
    return (
      <Popover trigger="hover">
        <PopoverTrigger>
          <Box minWidth={500} cursor="help" bg="green.500" rounded="lg" p="10px" m="10px">
            <Link to={`/show/${id}`}>{first}</Link>
          </Box>
        </PopoverTrigger>
        <PopoverContent zIndex={4} color="black" minWidth={500}>
          {all.map((d) => (
            <Box key={d} m="5px">
              {d}
            </Box>
          ))}
        </PopoverContent>
      </Popover>
    )
  }
  return (
    <Button
      variantColor="red"
      isLoading={updating}
      loadingText="En cours..."
      onClick={() => {
        setUpdating(true)
        setError(null)
        updateShow(id)
          .then((res) => {
            setUpdating(null)
            setRes(res)
            if (typeof after === "function") after()
          })
          .catch(setError)
      }}
    >{`${add ? "Ajouter" : "Mettre à jour"} la série`}</Button>
  )
}
export default ButtonUpdateShow
